export const getMobileOperatingSystem: any = () => {
  const userAgent = navigator && (navigator.userAgent || navigator.vendor);
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // fallback:
  return 'iOS';
};
