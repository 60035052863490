import { useEffect, useState } from 'react';

import redCheckCircle from '../../assets/image/red-check-circle.png';
import greenCheckCircle from '../../assets/image/green-check-circle.png';

interface BottomConfirmationPopupProps {
  confirmationType: string;
  msgTxt: string;
}

function BottomConfirmationPopup({ confirmationType, msgTxt }: BottomConfirmationPopupProps) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (confirmationType) {
      setTimeout(() => {
        setActive(true);
      }, 1);
      setTimeout(() => {
        setActive(true);
        setActive(false);
      }, 2001);
    }
  }, [confirmationType]);

  return (
    <section className={`bottom-confirmation-popup ${active ? 'active' : ''}`}>
      <div className="bottom-confirmation-popup-content">
        <img
          className="popup-icon"
          src={confirmationType === 'booked' ? greenCheckCircle : redCheckCircle}
          alt=""
        />
        <p className="popup-msg">{msgTxt}</p>
      </div>
    </section>
  );
}

export default BottomConfirmationPopup;
