import { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import useWindowSize from '../../hooks/useWindowSize';

interface TabSwitcherProps {
  isBooked: boolean;
  bodyScrollHeight: number;
  setIsBooked: (a: boolean) => void | undefined;
  isClassStarting: boolean;
}

function TabSwitcher({
  isBooked,
  bodyScrollHeight,
  setIsBooked,
  isClassStarting,
}: TabSwitcherProps) {
  const [isScrolled, setIsScrolled] = useState(false);
  const windowSize = useWindowSize();
  const screenWidth = windowSize.width || 768;
  const refBookedBtn = useRef<HTMLButtonElement>(null);
  const refUpcomingBtn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (
      (screenWidth < 768 && bodyScrollHeight > 10) ||
      (screenWidth >= 768 && bodyScrollHeight > 20)
    ) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [bodyScrollHeight, screenWidth]);

  return (
    <div className={`tab-switcher ${isScrolled ? 'isScrolled' : ''} flex justify-center`}>
      <div className={`tab-switcher__wrap ${isScrolled ? 'isScrolled' : ''}`}>
        <button
          type="button"
          onClick={() => setIsBooked(false)}
          className={`tab-switcher__btn ${!isBooked && 'tab-switcher__btn-active'}
          ${
            refUpcomingBtn.current && refUpcomingBtn.current?.innerText.length > 15
              ? 'smaller-font'
              : ''
          }
          `}
          ref={refUpcomingBtn}
        >
          <FormattedMessage id="tabSwitcher.upcoming" />
        </button>
        <button
          type="button"
          onClick={() => setIsBooked(true)}
          className={`tab-switcher__btn ${isBooked && 'tab-switcher__btn-active'}
          ${
            refBookedBtn.current && refBookedBtn.current?.innerText.length > 15
              ? 'smaller-font'
              : ''
          }
          `}
          ref={refBookedBtn}
        >
          <FormattedMessage id="tabSwitcher.booked" />
        </button>
        <div className={`active-indicator ${isBooked && 'booked'}`} />
        {isClassStarting && <div className="red-indicator" />}
      </div>
    </div>
  );
}

export default TabSwitcher;
