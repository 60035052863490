import { FormattedMessage } from 'react-intl';

import liveClassesImage from '../../assets/image/live-classes.png';
import clockImage from '../../assets/image/clock.png';
import classSizeImage from '../../assets/image/cards-class-size.png';
import starImage from '../../assets/image/Star.png';
import BetaTag from '../Shared/BetaTag';

function WelcomeModal() {
  return (
    <section className="welcome-modal">
      <BetaTag />
      <div className="welcome__title">
        <FormattedMessage id="welcomeModal.whatIsMondlyClass" />
      </div>
      <ul className="welcome__list">
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={liveClassesImage} alt="Level-icon" />
            <FormattedMessage id="welcomeModal.learnInASmallGroup" />
          </div>
        </li>
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={clockImage} alt="Level-icon" />
            <FormattedMessage id="welcomeModal.classesAre45MinLong" />
          </div>
        </li>
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={classSizeImage} alt="Level-icon" />
            <FormattedMessage id="welcomeModal.taughtByExperiencedPearsonTutors" />
          </div>
        </li>
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={starImage} alt="Level-icon" />
            <FormattedMessage id="welcomeModal.firstClassIsFree" />
          </div>
        </li>
      </ul>
    </section>
  );
}

export default WelcomeModal;
