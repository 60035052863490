import { Dispatch } from 'redux';
import { getUser } from '../../helpers/Network';
import { User } from '../../helpers/types';
import { Action, ActionType } from '../actionTypes';

export const fetchUser = (fakeUser?: boolean, userId?: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_LOADING,
      payload: { loading: true },
    });
    if (fakeUser) {
      dispatch({
        type: ActionType.SET_TOKEN,
        payload: {
          token: `fake_token_${userId || 'fake1'}`,
        },
      });
      dispatch({
        type: ActionType.SET_USER,
        payload: {
          user: {
            id: userId || 'fake1',
            email: 'fake@email.com',
            name: 'Fake User',
            roles: {
              premium: !userId?.includes('free'),
              premiumLiveTutoring: !userId?.includes('free'),
            },
          },
        },
      });
    } else {
      try {
        const resp = await window.mondlyNative?.getUserToken();
        const token = resp?.token;
        dispatch({
          type: ActionType.SET_TOKEN,
          payload: {
            token,
          },
        });
        let user: User | undefined;
        if (token) {
          user = await getUser(token);
          if (user) {
            user.id = user.id.toString();
            dispatch({
              type: ActionType.SET_USER,
              payload: {
                user,
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
        console.log(JSON.stringify(err));
      }
    }
    dispatch({
      type: ActionType.SET_LOADING,
      payload: { loading: false },
    });
  };
};

export const updateUserName = (userName: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_USER_NAME,
      payload: {
        userName,
      },
    });
  };
};
