import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SurveyQuestion } from '../../helpers/types';
import { ReactComponent as ThumbUp } from '../../assets/image/thumb-up-svg.svg';
import { ReactComponent as ThumbDown } from '../../assets/image/thumb-down-svg.svg';
import { ReactComponent as ThumbUpFull } from '../../assets/image/thumb-up-full-svg.svg';
import { ReactComponent as ThumbDownFull } from '../../assets/image/thumb-down-full-svg.svg';

interface FeedbackQuestionProps {
  questionType: string;
  setSurveyResponse: any;
  surveyResponse: SurveyQuestion[];
}

function FeedbackQuestion({
  questionType,
  setSurveyResponse,
  surveyResponse,
}: FeedbackQuestionProps) {
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'feedback.pleaseAddAnyAdditionalDetails' });

  const initialFeedbackQuestion = surveyResponse.find((el) => el.type === questionType) || {
    type: questionType,
    feedback: 0,
    comment: '',
  };
  const [feedbackQuestion, setFeedbackQuestion] = useState<SurveyQuestion>(initialFeedbackQuestion);

  const handleSurveyResponseChange = () => {
    const updatedSurveyResponse = surveyResponse.map((el) =>
      el.type === questionType ? { ...feedbackQuestion } : el,
    );
    setSurveyResponse(updatedSurveyResponse);
  };

  useMemo(() => {
    const updatedFeedbackQuestion = { ...initialFeedbackQuestion, type: questionType };
    setFeedbackQuestion(updatedFeedbackQuestion);
  }, [questionType]);

  useEffect(() => {
    handleSurveyResponseChange();
  }, [feedbackQuestion]);

  const handleCommentChange = ({ target }: any) => {
    const { value } = target;
    const updatedFeedbackQuestion = { ...feedbackQuestion, comment: value };
    setFeedbackQuestion(updatedFeedbackQuestion);
    handleSurveyResponseChange();
  };

  const handleFeedbackChange = (val: number) => {
    const updatedFeedbackQuestion = { ...feedbackQuestion, feedback: val };
    setFeedbackQuestion(updatedFeedbackQuestion);
    handleSurveyResponseChange();
  };

  const getFeedbackQuestionTextByType = () => {
    switch (questionType) {
      case '1':
        return <FormattedMessage id="feedback.didYourTutorHelpYouMeetTheObjectives" />;
      case '2':
        return <FormattedMessage id="feedback.didYouFindTheContentEngaging" />;
      case '3':
        return <FormattedMessage id="feedback.didYouOrTheTeacherExperienceAnyTechnicalIssues" />;

      default:
        return <FormattedMessage id="feedback.didYourTutorHelpYouMeetTheObjectives" />;
    }
  };

  return (
    <section className="feedback-question-container">
      <div className="feedback-question">
        <p className="feedback-question-txt">{getFeedbackQuestionTextByType()}</p>
        <div className="feedback-question-btns flex align-center justify-center">
          <button
            type="button"
            className={`left-btn flex align-center justify-center ${
              feedbackQuestion.feedback === 1 ? 'active' : ''
            }
            ${feedbackQuestion.feedback === -1 ? 'lower-opacity' : ''}
            `}
            onClick={() => handleFeedbackChange(1)}
          >
            {feedbackQuestion.feedback === 1 ? <ThumbUpFull /> : <ThumbUp />}
          </button>
          <button
            type="button"
            className={`right-btn flex align-center justify-center ${
              feedbackQuestion.feedback === -1 ? 'active' : ''
            }  ${feedbackQuestion.feedback === 1 ? 'lower-opacity' : ''}`}
            onClick={() => handleFeedbackChange(-1)}
          >
            {feedbackQuestion.feedback === -1 ? <ThumbDownFull /> : <ThumbDown />}
          </button>
        </div>
        <textarea
          name="FeedbackQuestionTextarea"
          id="FeedbackQuestionTextarea"
          className="feedback-question-textarea"
          placeholder={placeholder}
          value={feedbackQuestion.comment}
          onChange={(ev) => handleCommentChange(ev)}
        />
      </div>
    </section>
  );
}

export default FeedbackQuestion;
