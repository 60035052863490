import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import mixpanel from 'mixpanel-browser';
import { feedbackSession, getContentEnv } from '../../helpers/Network';
import FeedbackQuestion from './FeedbackQuestion';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import BottomConfirmationPopup from '../Shared/BottomConfirmationPopup';
import ProgressBar from '../Shared/ProgressBar';
import { SurveyQuestion } from '../../helpers/types';

function PostSessionScreens() {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { token } = useTypedSelector((state) => state.user);
  const initialSurveyResponse = [
    { type: '1', feedback: 0, comment: '' },
    { type: '2', feedback: 0, comment: '' },
    { type: '3', feedback: 0, comment: '' },
  ];
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState<SurveyQuestion[]>(initialSurveyResponse);
  const [currQuestionType, setCurrQuestionType] = useState('1');
  const [showLastCheckmark, setShowLastCheckmark] = useState(false);
  const showCTA = surveyResponse[parseInt(currQuestionType, 10) - 1].feedback !== 0;

  useEffect(() => {
    // Report lesson ends
    if (getContentEnv() === 'prod') {
      window.mondlyNative
        ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_END, {
          lessonID: sessionId?.toString(),
          lessonStartTimestamp: Math.floor(Date.now() / 1000),
        })
        .catch((err) => console.log(err));
      window.mondlyNative
        ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_SCREEN_OPEN, {
          screenID: window.mondlyNative.screenIds.FEEDBACK,
          lessonID: sessionId?.toString(),
        })
        .catch((err) => console.log(err));
    }
    try {
      // Mixpanel metric:
      mixpanel.track('FeedbackOpen', {
        SessionId: sessionId,
      });
    } catch (err) {
      console.log(err);
    }
  }, [sessionId]);

  const handleSubmit = () => {
    try {
      // Mixpanel metric:
      mixpanel.track('FeedbackSubmit', {
        SessionId: sessionId,
        feedbackResponse: surveyResponse,
      });
    } catch (err) {
      console.log(err);
    }
    setShowLastCheckmark(true);
    if (showCTA) {
      if (token && sessionId) {
        feedbackSession(token, sessionId, surveyResponse).catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
      }
      if (surveyResponse === initialSurveyResponse) {
        navigate('/');
      } else {
        setIsShowConfirmationPopup(true);
        setTimeout(() => {
          setIsShowConfirmationPopup(false);
          navigate('/');
        }, 2100);
      }
    }
  };

  const onChangeQuestionType = () => {
    if (showCTA) setCurrQuestionType((parseInt(currQuestionType, 10) + 1).toString());
  };

  const getCTAFormattedMsg = () => {
    return currQuestionType === '3' ? (
      <FormattedMessage id="feedback.submit" />
    ) : (
      <FormattedMessage id="feedback.continue" />
    );
  };

  return (
    <section className="post-session-screens">
      <div className="post-session-survey flex column">
        <FeedbackQuestion
          questionType={currQuestionType}
          surveyResponse={surveyResponse}
          setSurveyResponse={setSurveyResponse}
        />
        <ProgressBar
          questionType={currQuestionType}
          setCurrQuestionType={setCurrQuestionType}
          showLastCheckmark={showLastCheckmark}
        />

        <button
          type="button"
          className={`cta-btn ${showCTA === false ? 'cta-hide' : ''}`}
          onClick={currQuestionType === '3' ? handleSubmit : onChangeQuestionType}
        >
          {getCTAFormattedMsg()}
        </button>
      </div>

      {isShowConfirmationPopup && (
        <BottomConfirmationPopup confirmationType="booked" msgTxt="Thank you for your feedback!" />
      )}
    </section>
  );
}
export default PostSessionScreens;
