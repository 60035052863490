import axios from 'axios';
import { SurveyQuestion, User } from './types';

export const getContentEnv = () => {
  let params: Record<string, string> = {};
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams.entries());
  }
  return params.contentEnv || process.env.REACT_APP_CONTENT_ENV || 'prod';
};

const tutoringApiUrl = () => {
  switch (getContentEnv()) {
    case 'stage':
      return 'https://api.tutoring-stg.pearsondev.tech';
    case 'prod':
      return 'https://api.tutoring-prd.pearsonprd.tech';
    default:
      return 'https://api.tutoring-dev.pearsondev.tech';
  }
};

export const getUser = async (token: string) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const resp = await axios.get<{ user: User }>(
      'https://api.mondly.com/v1/services/live-tutoring/user/info',
      options,
    );
    return resp.data?.user;
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
    return undefined;
  }
};

export const updateUserNameMondly = async (token: string, name: string): Promise<void> => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    await axios.post(
      'https://api.mondly.com/v1/services/live-tutoring/settings/update-profile',
      { name },
      options,
    );
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
  }
};

export const getSessions = async (userId: string, token: string, lang: string) => {
  const options = {
    headers: {
      'X-Authorization': `Bearer ${token}`,
    },
  };
  try {
    const resp = await axios.get(`${tutoringApiUrl()}/v1/sessions/upcoming/${lang || ''}`, options);
    return resp.data;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const enrollUserToSession = async (
  tenantId: string,
  userId: string,
  token: string,
  sessionId: number,
) => {
  const options = {
    headers: {
      'X-Authorization': `Bearer ${token}`,
    },
  };
  try {
    const resp = await axios.post(
      `${tutoringApiUrl()}/v1/sessions/${sessionId}/enroll`,
      null,
      options,
    );
    return resp.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const cancelUserToSession = async (
  tenantId: string,
  userId: string,
  token: string,
  sessionId: number,
) => {
  const options = {
    headers: {
      'X-Authorization': `Bearer ${token}`,
    },
  };
  try {
    const resp = await axios.post(
      `${tutoringApiUrl()}/v1/sessions/${sessionId}/cancel`,
      null,
      options,
    );
    return resp.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const joinNewSession = async (sessionId: number, token: string, userName: string) => {
  const options = {
    headers: {
      'X-Authorization': `Bearer ${token}`,
    },
  };
  try {
    const resp = await axios.get(
      `${tutoringApiUrl()}/v1/sessions/${sessionId}/joinSession?userName=${userName}`,
      options,
    );
    return resp.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const checkIfMeetingStarted = async (sessionId: number): Promise<boolean> => {
  try {
    const resp = await axios.get(`${tutoringApiUrl()}/v1/sessions/${sessionId}/isMeetingRunning`);
    return resp.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const feedbackSession = async (
  token: string,
  sessionId: string,
  feedback: SurveyQuestion[],
) => {
  const options = {
    headers: {
      'X-Authorization': `Bearer ${token}`,
    },
  };
  try {
    const resp = await axios.post(
      `${tutoringApiUrl()}/v1/sessions/${sessionId}/feedback`,
      feedback,
      options,
    );
    return resp.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};
