function BetaTag() {
  return (
    <section className="beta-tag">
      <div className="beta-tag-txt">
        <p>Beta</p>
      </div>
    </section>
  );
}

export default BetaTag;
