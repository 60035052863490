import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import mixpanel from 'mixpanel-browser';
import { Avatar, Modal } from '@mui/material';
import { differenceInMinutes, format, areIntervalsOverlapping, addMinutes } from 'date-fns';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import RestricitonAlert from '../Modals/RestrictionAlert';
import { Session } from '../../helpers/types';
import levelImage from '../../assets/image/cards-level.png';
import classSizeImage from '../../assets/image/cards-class-size.png';
import calendarImage from '../../assets/image/cards-calendar.png';
import useWindowSize from '../../hooks/useWindowSize';
import { getContentEnv } from '../../helpers/Network';

interface ClassCardProps {
  testWeb: string | null;
  isBooked: boolean;
  session: Session;
  bookedSessionsLength: number;
  updatedSessions: Session[];
  enrollSession: (sessionId: number, sessionTime: number) => void;
  cancelSession: (sessionId: number) => void;
  joinSession: (sessionId: number) => void;
  showAgreeScreen: string;
  setShowPreClass: (a: boolean) => void | undefined;
  setShowUserAgreementScreen: (a: boolean) => void | undefined;
  setShowPreScreen: (a: boolean) => void | undefined;
  setCurrenSession: (a: Session) => void | undefined;
}

function ClassCard({
  testWeb,
  isBooked,
  session,
  bookedSessionsLength,
  updatedSessions,
  enrollSession,
  cancelSession,
  joinSession,
  showAgreeScreen,
  setShowPreClass,
  setShowPreScreen,
  setShowUserAgreementScreen,
  setCurrenSession,
}: ClassCardProps) {
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const { os } = useTypedSelector((state) => state.device);
  const [openRestrictionMsgModal, setOpenRestrictionMsgModal] = useState(false);
  const refDescTextHidden = useRef<HTMLDivElement>(null);
  const refDescText = useRef<HTMLDivElement>(null);
  const refDescSeeMore = useRef<HTMLSpanElement>(null);
  const clickCount = useRef(0);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [isSessionBooked, setIsSessionBooked] = useState(false);
  const [descTruncatedLength, setDescTruncatedLength] = useState(0);
  const [restrictionMsgType, setRestrictionMsgType] = useState('');
  const windowSize = useWindowSize();
  const screenWidth = windowSize.width;

  const isSessionOverlapping = () => {
    return updatedSessions.some((item: { enrollDate: any; date: string | number | Date }) => {
      return (
        item.enrollDate &&
        areIntervalsOverlapping(
          { start: new Date(item.date), end: addMinutes(new Date(item.date), 44) },
          { start: new Date(session.date), end: addMinutes(new Date(session.date), 44) },
          { inclusive: false },
        )
      );
    });
  };

  const getDateString = (date: Date) => {
    return format(date, 'eeee d MMM, HH:mm');
  };

  function onBookSession() {
    try {
      // Mixpanel metric:
      mixpanel.track('SessionBookBtnClicked', {
        SessionId: session.id,
      });
    } catch (err) {
      console.log(err);
    }
    if (!testWeb && getContentEnv() === 'prod') {
      // Report user press book session
      window.mondlyNative
        ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_ACTION, {
          lessonID: session.id.toString(),
          actionID: window.mondlyNative.lessonActionIds.BOOK,
          lessonStartTimestamp: Math.floor(Date.now() / 1000),
        })
        .catch((err) => console.log(err));
    }
    if (bookedSessionsLength >= 3) {
      setRestrictionMsgType('max3BookedSessions');
      setOpenRestrictionMsgModal(true);
    } else if (isSessionOverlapping()) {
      setRestrictionMsgType('noDoubleBooking');
      setOpenRestrictionMsgModal(true);
      // free single lesson, currently not part of launching:
      // } else if (!user?.roles.premiumLiveTutoring && bookedSessionsLength >= 1) {
      //   window.mondlyNative?.showPremiumPopup().catch((err) => console.log(err));
    } else if (showAgreeScreen === 'New') {
      setCurrenSession(session);
      setShowPreClass(true);
      setShowPreScreen(false);
      setShowUserAgreementScreen(true);
    } else {
      enrollSession(session.id, new Date(session.date).getTime());
    }
  }

  const getTextWidth = (text: string, font: any) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context!.font = font || getComputedStyle(document.body).font;
    return context!.measureText(text).width;
  };

  useEffect(() => {
    // "see more" logic for any screen and font:
    if (refDescText.current !== null && refDescTextHidden.current !== null) {
      const elComputedWidth = refDescText.current.clientWidth;
      const elComputedLength = refDescTextHidden.current.innerText.length;

      const charWidth = getTextWidth('a', null);
      const charsInLine = elComputedWidth / charWidth;
      const numOfLines = elComputedLength / charsInLine;
      let maxCharsWithSeeMore = elComputedLength;

      const entryCarriagePos = refDescTextHidden.current.innerText.indexOf('\n');
      if (numOfLines > 2 || entryCarriagePos !== -1) {
        setShowSeeMore(true);
        // calculate truncation length:
        let seeMoreComputedLength = 0;
        // number of chars in line:
        maxCharsWithSeeMore = Math.floor(charsInLine * 2 - seeMoreComputedLength);
        if (entryCarriagePos !== -1) {
          maxCharsWithSeeMore = Math.min(entryCarriagePos, maxCharsWithSeeMore);
        }
        if (refDescSeeMore.current !== null) {
          seeMoreComputedLength = refDescSeeMore.current.innerText.length;
        }
      }

      //
      setDescTruncatedLength(maxCharsWithSeeMore);
    }
  }, [session.template.description]);

  function handleCancelModalClose() {
    setOpenCancelModal(false);
  }

  function handleCancelModalYesBtn() {
    setIsSessionBooked(false);
    cancelSession(session.id);
    try {
      // Mixpanel metric:
      mixpanel.track('CancelSessionBtnClicked', {
        SessionId: session.id,
      });
    } catch (err) {
      console.log(err);
    }
    handleCancelModalClose();
  }

  function handleJoinSession() {
    joinSession(session.id);
    try {
      // Mixpanel metric:
      mixpanel.track('JoinSessionBtnClicked', {
        SessionId: session.id,
      });
    } catch (err) {
      console.log(err);
    }
  }

  function handleClikcOnTutorTitle() {
    const newValue = clickCount.current + 1;
    clickCount.current = newValue;
    if (clickCount.current === 5) {
      clickCount.current = 0;
      joinSession(session.id);
    }
    setTimeout(() => {
      clickCount.current = 0;
    }, 5000);
  }

  const openFullDescription = () => {
    setShowSeeMore(false);
    setDescTruncatedLength(refDescTextHidden.current!.innerText.length);
  };

  return (
    <section
      className={`class-card-container ${!isBooked && isSessionBooked ? 'session-booked' : ''}`}
    >
      <div className="class-card">
        <div className="card-header flex justify-start">
          <div className="avatar-container">
            <Avatar
              src={session?.tutor?.thumb}
              sx={{
                width: screenWidth! > 768 ? 100 : 57,
                height: screenWidth! > 768 ? 100 : 57,
              }}
              alt={`${session.tutor.firstName} ${session.tutor.lastName}`}
            />
          </div>
          <div onClick={() => handleClikcOnTutorTitle()} aria-hidden>
            <div className="card-title">{session?.template?.title}</div>
            <div className="card-subtitle font-bold flex">
              <div className="flex-1">
                {session.tutor.firstName} {session.tutor.lastName}
              </div>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="card-main">
          <div className="class-info-item class-level flex justify-start align-center">
            <img className="card-icon-img" src={levelImage} alt="Level-icon" />
            <FormattedMessage
              id={`classCard.level.${session.template.level}`}
              defaultMessage={session.template.level}
            />
          </div>
          <div className="class-info-item flex justify-start align-center">
            <img className="card-icon-img" src={calendarImage} alt="calendar-icon" />
            {(differenceInMinutes(new Date(session?.date), Date.now()) <= 15 && isBooked) ||
            (differenceInMinutes(new Date(session?.date), Date.now()) <= 15 && isSessionBooked) ? (
              <div className="warning">
                <p>
                  <FormattedMessage id="classCard.your" /> &nbsp;
                  {session && <span>{format(new Date(session?.date), 'HH:mm')}</span>} &nbsp;
                  <FormattedMessage id="classCard.classIsReady" />
                </p>
              </div>
            ) : (
              <div className="capitalize">{getDateString(new Date(session?.date))}</div>
            )}
          </div>

          <div className="class-info-item class-size flex justify-start align-center ">
            <img className="card-icon-img" src={classSizeImage} alt="call-size-icon" />
            {/* case 1: class is full */}
            {!isBooked &&
              !isSessionBooked &&
              session?.enrolledUsers === session.maxParticipants && (
                <div className="warning">This class is full</div>
              )}
            {/* case 2: yellow (warning) bold text if class is n-1/n full */}
            {!isBooked &&
              !isSessionBooked &&
              session?.enrolledUsers !== session.maxParticipants && (
                <div
                  className={session?.enrolledUsers >= session.maxParticipants - 2 ? 'warning' : ''}
                >
                  {`${session?.enrolledUsers || 0}/${session.maxParticipants} `}
                  <FormattedMessage id="classCard.peopleAreAttendingThisClass" />
                </div>
              )}
            {/* case 3: booked */}
            {(isBooked || (!isBooked && isSessionBooked)) && (
              <div className="warning">
                <FormattedMessage id="classCard.youAreBookedIn" />
              </div>
            )}
          </div>

          {session?.template?.description && (
            <div className="class-description">
              <p className="reference-desc hidden" ref={refDescTextHidden}>
                {session.template.description}
              </p>
              <p ref={refDescText}>
                {session.template.description.substring(0, descTruncatedLength)}
                {showSeeMore && <span>... </span>}
                {showSeeMore && (
                  <span
                    aria-hidden
                    ref={refDescSeeMore}
                    className="see-more"
                    onClick={() => openFullDescription()}
                  >
                    <FormattedMessage id="classCard.seeMore" />
                  </span>
                )}
              </p>
            </div>
          )}
        </div>

        {!isBooked &&
          !isSessionBooked &&
          (session.enrolledUsers ? session.enrolledUsers : 0) < session.maxParticipants && (
            <div
              className={`class-action-btns ${
                !isBooked && isSessionBooked ? 'session-booked' : ''
              }`}
            >
              <button type="button" className="cta-btn card-cta" onClick={() => onBookSession()}>
                <FormattedMessage id="classCard.book" />
              </button>
            </div>
          )}

        {(isBooked || (!isBooked && isSessionBooked)) && (
          <div className="class-action-btns">
            {differenceInMinutes(new Date(session?.date), Date.now()) <= 15 && (
              <button
                type="button"
                className="cta-btn card-cta join-btn"
                onClick={() => handleJoinSession()}
              >
                <FormattedMessage id="common.join" />
              </button>
            )}

            {differenceInMinutes(new Date(session?.date), Date.now()) > 15 && (
              <button
                type="button"
                className="class-cancel-btn"
                onClick={() => setOpenCancelModal(true)}
              >
                <FormattedMessage id="classCard.cancelBooking" />
              </button>
            )}
          </div>
        )}
      </div>
      <Modal open={openCancelModal} onClose={() => handleCancelModalClose()}>
        <div className={`${os !== 'iOS' ? 'android' : ''} modal-panel`}>
          <div className={`modal-content${os !== 'iOS' ? '-android' : ''}`}>
            <h4 className={`${os !== 'iOS' ? 'title' : 'modal-content__title'}`}>
              <FormattedMessage id="classCard.areYouSureYoudLikeToCancel" />
            </h4>
          </div>
          <div className={`modal-footer${os !== 'iOS' ? '-android' : ''}`}>
            <button
              className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__danger'}`}
              type="button"
              onClick={() => handleCancelModalYesBtn()}
            >
              <FormattedMessage id="common.yes" />
            </button>
            <button
              className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__default'}`}
              type="button"
              onClick={() => handleCancelModalClose()}
            >
              <FormattedMessage id="common.no" />
            </button>
          </div>
        </div>
      </Modal>
      <Modal open={openRestrictionMsgModal} onClose={() => setOpenRestrictionMsgModal(false)}>
        <div className={`${os !== 'iOS' ? 'android' : ''} modal-panel`}>
          <div className={`modal-content${os !== 'iOS' ? '-android' : ''}`}>
            <h4 className={`${os !== 'iOS' ? 'title' : 'modal-content__title'}`}>
              <RestricitonAlert restrictionMsgType={restrictionMsgType} />
            </h4>
          </div>
          <div className={`modal-footer${os !== 'iOS' ? '-android' : ''}`}>
            <button
              className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__default'}`}
              type="button"
              onClick={() => setOpenRestrictionMsgModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default ClassCard;
