import { Session, Template, Tutor, User } from '../../helpers/types';
import { Action, ActionType } from '../actionTypes';

interface State {
  sessions?: Session[];
  tutors: Tutor[];
  templates: Template[];
  loading: boolean;
  token?: string;
  user?: User;
}

const initialState: State = {
  sessions: undefined,
  tutors: [],
  templates: [],
  loading: false,
  token: undefined,
  user: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function SessionsReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionType.SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload.sessions,
        tutors: action.payload.tutors,
        templates: action.payload.templates,
      };
    case ActionType.SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case ActionType.SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case ActionType.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
}

export default SessionsReducer;
