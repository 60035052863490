import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { setDefaultOptions } from 'date-fns';
import { enUS, ptBR, es } from 'date-fns/locale';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import { store } from './redux/store';
import enDictionary from './translations/en.json';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import esDictionary from './translations/es.json';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ptDictionary from './translations/br.json';

const NavLocale = navigator.language;
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
let locale;

let lang;

switch (params.mother_lang) {
  case 'en_us':
    lang = enDictionary;
    locale = enUS;
    break;
  case 'es':
    lang = esDictionary;
    locale = es;

    break;
  case 'br':
    lang = ptDictionary;
    locale = ptBR;

    break;

  default:
    lang = enDictionary;
    locale = enUS;

    break;
}

const difficulty = parseInt(params.difficulty, 10) || 1;
// define app-wide date-fns locale:
setDefaultOptions({ locale });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <LDProvider clientSideID={process.env.REACT_APP_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID || '-1'}>
    <Provider store={store}>
      <IntlProvider locale={NavLocale} messages={lang}>
        <App lang={params.mother_lang} difficulty={difficulty} />
      </IntlProvider>
    </Provider>
  </LDProvider>,
);
