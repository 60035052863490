import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import { Action } from './actionTypes';
import reducers, { RootState } from './reducers';

export type TypedDispatch = typeof store.dispatch;
export type TypedThunk<R = void> = ThunkAction<R, RootState, unknown, Action>;

export type AppDispatch = typeof store.dispatch;
export const store = createStore(reducers, applyMiddleware(thunkMiddleware));
