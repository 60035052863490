import { User } from '../../helpers/types';
import { Action, ActionType } from '../actionTypes';

interface State {
  token?: string;
  user?: User;
}

const initialState: State = {
  token: undefined,
  user: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function UserReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionType.SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case ActionType.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case ActionType.SET_USER_NAME: {
      const copyState = { ...state };
      if (copyState.user) {
        copyState.user.name = action.payload.userName;
      }
      return copyState;
    }
    default:
      return state;
  }
}

export default UserReducer;
