import { Dispatch } from 'redux';
import { cancelUserToSession, enrollUserToSession, getSessions } from '../../helpers/Network';
import { Action, ActionType } from '../actionTypes';

export const getSessionsD = (userId: string, token: string, lang: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const data = await getSessions(userId, token, lang);
    if (data) {
      dispatch({
        type: ActionType.SET_SESSIONS,
        payload: {
          sessions: data.upComingSessions.sessions,
          templates: data.upComingSessions.templates,
          tutors: data.upComingSessions.tutors,
        },
      });
    }
  };
};

export const enrollSessionD = (
  sessionId: number,
  tenant: string,
  userId: string,
  token: string,
) => {
  return async () => {
    let result = null;
    result = await enrollUserToSession(tenant, userId, token, sessionId);
    return result;
  };
};

export const cancelSessionD = (
  sessionId: number,
  tenant: string,
  userId: string,
  token: string,
) => {
  return async (dispatch: Dispatch<Action>) => {
    let result = null;
    dispatch({
      type: ActionType.SET_LOADING,
      payload: { loading: true },
    });
    result = await cancelUserToSession(tenant, userId, token, sessionId);
    dispatch({
      type: ActionType.SET_LOADING,
      payload: { loading: false },
    });
    return result;
  };
};
