import { useEffect, useState } from 'react';
import checkmarkImage from '../../assets/image/checkmark.png';

interface ProgressBarProps {
  questionType: string;
  setCurrQuestionType: (a: string) => void;
  showLastCheckmark: boolean;
}

function ProgressBar({ questionType, setCurrQuestionType, showLastCheckmark }: ProgressBarProps) {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    if (questionType === '1') {
      setPercent(0);
    }
    if (questionType === '2') {
      setPercent(50);
    }
    if (questionType === '3') {
      setPercent(100);
    }
  }, [questionType]);

  return (
    <section className="progress-bar-container">
      <div className="progress-bar">
        <div className="checkpoints">
          <button
            type="button"
            className="checkpoint active"
            onClick={() => setCurrQuestionType('1')}
          >
            {questionType !== '1' ? <img src={checkmarkImage} className="checkmark" alt="" /> : 1}
          </button>
          <button
            type="button"
            className={`checkpoint ${questionType === '2' || questionType === '3' ? 'active' : ''}`}
            onClick={() => setCurrQuestionType('2')}
          >
            {questionType === '3' ? <img src={checkmarkImage} className="checkmark" alt="" /> : 2}
          </button>
          <button
            type="button"
            className={`checkpoint ${questionType === '3' ? 'active' : ''}`}
            onClick={() => setCurrQuestionType('3')}
          >
            {showLastCheckmark && questionType === '3' ? (
              <img src={checkmarkImage} className="checkmark" alt="" />
            ) : (
              3
            )}
          </button>
        </div>
        <div className="emptyProgressBar" style={{ width: '100%' }}>
          <div
            className="fillingProgressBar"
            style={{
              left: `${percent - 100}%`,
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default ProgressBar;
