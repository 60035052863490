import { Session, Template, Tutor, User } from '../../helpers/types';

export enum ActionType {
  SET_SESSIONS = 'SET_SESSIONS',
  SET_LOADING = 'SET_LOADING',
  SET_TOKEN = 'SET_TOKEN',
  SET_USER = 'SET_USER',
  SET_USER_NAME = 'SET_USER_NAME',
  SET_OS = 'SET_OS',
  SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH',
}

interface ActionSetSessions {
  type: ActionType.SET_SESSIONS;
  payload: {
    sessions: Session[];
    tutors: Tutor[];
    templates: Template[];
  };
}

interface ActionSetLoading {
  type: ActionType.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

interface ActionSetToken {
  type: ActionType.SET_TOKEN;
  payload: {
    token: string | undefined;
  };
}

interface ActionSetUser {
  type: ActionType.SET_USER;
  payload: {
    user: User;
  };
}
interface ActionSetUserName {
  type: ActionType.SET_USER_NAME;
  payload: {
    userName: string;
  };
}

interface ActionSetOs {
  type: ActionType.SET_OS;
  payload: {
    os: string;
  };
}

export type Action =
  | ActionSetSessions
  | ActionSetLoading
  | ActionSetUser
  | ActionSetToken
  | ActionSetUserName
  | ActionSetOs;
