import { getMobileOperatingSystem } from '../../helpers/utils';
import { Action, ActionType } from '../actionTypes';

interface State {
  os?: string;
}

const initialState: State = {
  os: getMobileOperatingSystem(),
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function DeviceReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionType.SET_OS:
      return {
        ...state,
        os: action.payload.os,
      };
    default:
      return state;
  }
}

export default DeviceReducer;
