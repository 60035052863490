import { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import mixpanel from 'mixpanel-browser';
import { differenceInMinutes } from 'date-fns';
import { Session, User } from '../../helpers/types';
import { checkIfMeetingStarted, getContentEnv } from '../../helpers/Network';
import BetaTag from '../Shared/BetaTag';

interface PreClassScreenProps {
  user: User;
  buildUserData: (userName: string) => void;
  sessionData: Session;
  setTermsAndConditionsModal: (a: boolean) => void | undefined;
  showUserAgreementScreen: boolean;
  showPreScreen: boolean;
  userAgreement: boolean;
  showAgreeScreen: string;
  setUserAgreement: (a: boolean) => void | undefined;
  setShowPreScreen: (a: boolean) => void | undefined;
  enrollSession: (sessionId: number, sessionTime: number) => void;
}

function PreClassScreen({
  buildUserData,
  sessionData,
  user,
  setTermsAndConditionsModal,
  showUserAgreementScreen,
  showPreScreen,
  userAgreement,
  showAgreeScreen,
  setUserAgreement,
  setShowPreScreen,
  enrollSession,
}: PreClassScreenProps) {
  const [queryParams] = useSearchParams();
  const testWeb = queryParams.get('testWeb');
  const [timeTillLessonInMinutes, setTimeTillLessonInMinutes] = useState(
    sessionData.date ? differenceInMinutes(new Date(sessionData.date), Date.now()) : -1,
  );

  // const userAgreementStorage = useMemo(() => {
  //   try {
  //     return localStorage.getItem(`${user.id}_consent_agreement`);
  //   } catch (e) {
  //     console.log('error:', e);
  //     return false;
  //   }
  // }, []);
  // const [userAgreement, setUserAgreement] = useState(!!userAgreementStorage);
  const [joinBtn, setJoinBtn] = useState(!!user.name);
  const [userName, setUserName] = useState(user.name);
  const [isMeetingStarted, setIsMeetingStarted] = useState(false);
  // const [showUserAgreementScreen, setShowUserAgreementScreen] = useState(
  //   !userAgreement || !user.name,
  // );

  const clickCount = useRef(0);

  const navigate = useNavigate();
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'preClassScreen.name' });

  const nameInput = (name: string) => {
    setUserName(name);
    if (name.length >= 2) {
      setJoinBtn(true);
    } else {
      setJoinBtn(false);
    }
  };

  const handleClickOnCard = () => {
    const newValue = clickCount.current + 1;
    clickCount.current = newValue;
    if (clickCount.current === 5) {
      clickCount.current = 0;
      let sessionUrl = `/session/${sessionData.id}`;
      if (testWeb) {
        sessionUrl += '?testWeb=true';
      }
      navigate(sessionUrl);
    }
  };

  useEffect(() => {
    if (!testWeb && getContentEnv() === 'prod') {
      window.mondlyNative
        ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_SCREEN_OPEN, {
          screenID: !userAgreement
            ? window.mondlyNative.screenIds.PRE_SESSION
            : window.mondlyNative.screenIds.WAITING,
          lessonID: sessionData.id.toString(),
        })
        .catch((err) => console.log(err));
    }
  }, [testWeb, sessionData.id, showUserAgreementScreen, userAgreement]);

  // const validateEmail = (email: string) => {
  //   return String(email)
  //     .toLowerCase()
  //     .match(
  //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //     );
  // };

  // const emailInput = (email: string) => {
  //   setUserEmail(email);
  // };

  const updateUserData = () => {
    // if (validateEmail(userEmail) || userEmail === '') {
    //   setErrorMailFormal(false);
    // const userAgreement = localStorage.getItem(`${user.id}_consent_agreement`);
    if (!userAgreement) {
      try {
        localStorage.setItem(`${user.id}_consent_agreement`, JSON.stringify(true));
      } catch (e) {
        console.log('error', e);
      }
      setUserAgreement(true);
      if (!testWeb && getContentEnv() === 'prod') {
        window.mondlyNative
          ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_SCREEN_INTERACTION, {
            screenID: window.mondlyNative.screenIds.PRE_SESSION,
            actionID: window.mondlyNative.presessionActionIds.ACCEPTED,
            lessonID: sessionData.id.toString(),
          })
          .catch((err) => console.log(err));
      }
    }
    // setShowUserAgreementScreen(false);
    if (showAgreeScreen === 'Old') {
      setShowPreScreen(true);
    } else {
      enrollSession(sessionData.id, new Date(sessionData.date).getTime());
      try {
        // Mixpanel metric:
        mixpanel.track('EnrollSession', {
          SessionId: sessionData.id,
        });
      } catch (err) {
        console.log(err);
      }
    }
    buildUserData(userName);

    // } else {
    //   setErrorMailFormal(true);
    // }
  };

  useEffect(() => {
    checkIfMeetingStarted(sessionData.id).then(setIsMeetingStarted);
    const isMeetingStartedInterval = setInterval(async () => {
      const started = await checkIfMeetingStarted(sessionData.id);
      setIsMeetingStarted(started);
      if (started) {
        clearInterval(isMeetingStartedInterval);
      }
    }, 1000 * 30);
    return () => {
      clearInterval(isMeetingStartedInterval);
    };
  }, [sessionData.id]);

  useEffect(() => {
    const timeTillSessionInterval = setInterval(() => {
      const timeDifference = differenceInMinutes(new Date(sessionData.date), new Date());
      setTimeTillLessonInMinutes(timeDifference);
      if (isMeetingStarted && timeDifference < 1 && userAgreement) {
        clearInterval(timeTillSessionInterval);
        let sessionUrl = `/session/${sessionData.id}`;
        if (testWeb) {
          sessionUrl += '?testWeb=true';
        }
        // else {
        //   // Report user press join session
        //   window.mondlyNative
        //     ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_ACTION, {
        //       lessonID: sessionData.id,
        //       actionID: window.mondlyNative.lessonActionIds.JOIN,
        //       lessonStartTimestamp: Math.floor(Date.now() / 1000),
        //     })
        //     .catch((err) => console.log(err));
        // }
        navigate(sessionUrl);
      }
    }, 1000 * 10);
    return () => {
      clearInterval(timeTillSessionInterval);
    };
  }, [
    navigate,
    sessionData.date,
    sessionData.id,
    setTimeTillLessonInMinutes,
    isMeetingStarted,
    testWeb,
    userAgreement,
  ]);

  return (
    <section className="pre-class-screen">
      <BetaTag />
      {showUserAgreementScreen && (
        <>
          <div className="pre-class-title">
            <FormattedMessage id="preClassScreen.yourDetails" />
          </div>
          <div className="pre-class-description">
            <FormattedMessage id="preClassScreen.pleaseProvideYourName" />
          </div>
          {/* <div className="pre-class-input-text">What is your name?</div> */}
          <form id="userName" action="submit">
            <input
              value={userName}
              id="inputName"
              type="text"
              className="pre-class-input input-name"
              placeholder={placeholder}
              onChange={(e) => {
                nameInput(e.target.value);
              }}
            />
          </form>
          <div className="pre-class-record-disclaimer">
            <div className="pre-class-info">i</div>
            <FormattedMessage id="preClassScreen.allClassesAreRecorded" />
          </div>
          {!userAgreement && (
            <div className="pre-class-disc-terms">
              <p>
                <FormattedMessage id="preClassScreen.byClicking" />
                &nbsp;
                <span className="italic">
                  “
                  <FormattedMessage
                    id={
                      showAgreeScreen === 'Old'
                        ? 'preClassScreen.agreeAndJoin'
                        : 'preClassScreen.agreeAndBook'
                    }
                  />
                  ”
                </span>
                &nbsp;
                <FormattedMessage id="preClassScreen.youIndicateConsentToThe" />
                &nbsp;
                <button
                  type="button"
                  className="terms-and-conditions-link"
                  onClick={() => setTermsAndConditionsModal(true)}
                >
                  <FormattedMessage id="preClassScreen.termsAndConditions" />
                </button>
                &nbsp;
                <FormattedMessage id="preClassScreen.andOver18" />
              </p>
            </div>
          )}

          <div className="pre-class-join-wrp flex justify-center">
            <button
              type="submit"
              form="userName"
              className={`cta-btn pre-class-join-btn ${!joinBtn ? 'disabled' : ''}`}
              onClick={() => updateUserData()}
              disabled={!joinBtn}
            >
              <FormattedMessage
                id={showAgreeScreen === 'Old' ? 'common.agreeAndJoin' : 'common.agreeAndBook'}
              />
            </button>
          </div>
        </>
      )}
      {showPreScreen && (
        <div className="holding-screen" onClick={() => handleClickOnCard()} aria-hidden>
          <div className="holding-screen-description">
            <FormattedMessage id="preClassScreen.YouWillBeAdmitted" />
            <br /> <br />
            <FormattedMessage id="preClassScreen.PleaseMakeSure" />
          </div>
        </div>
      )}

      {timeTillLessonInMinutes > 0 && (
        <div className="holding-screen-timer">
          <FormattedMessage id="preClassScreen.classStartsIn" /> &nbsp;
          <b>
            {Math.floor(timeTillLessonInMinutes / 60)}h {timeTillLessonInMinutes % 60}m
          </b>
        </div>
      )}
    </section>
  );
}

export default PreClassScreen;
