import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import mixpanel from 'mixpanel-browser';
import { differenceInMinutes } from 'date-fns';
import { Modal } from '@mui/material';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import TabSwitcher from '../Classes/TabSwitcher';
import ClassCard from '../Classes/ClassCard';
import BottomConfirmationPopup from '../Shared/BottomConfirmationPopup';
import { useTypedDispatch, useTypedSelector } from '../../hooks/useTypeSelector';
import {
  cancelSessionD,
  enrollSessionD,
  getSessionsD,
} from '../../redux/actionCreators/SessionsReducerAC';
import closeImage from '../../assets/image/close.png';
import { updateUserName } from '../../redux/actionCreators/UserReducerAC';
import { Session } from '../../helpers/types';
import OvalLoader from '../../assets/loader/oval';
import PreClassScreen from '../Modals/PreClassScreen';
import { getContentEnv, updateUserNameMondly } from '../../helpers/Network';

interface SessionsProps {
  bodyScrollHeight: number;
  setTermsAndConditionsModal: (a: boolean) => void | undefined;
  lang: string;
  difficulty: number;
}

function Sessions({
  bodyScrollHeight,
  setTermsAndConditionsModal,
  lang,
  difficulty,
}: SessionsProps) {
  const [queryParams] = useSearchParams();
  const testWeb = queryParams.get('testWeb');
  const [isBooked, setIsBooked] = useState(!!queryParams.get('showBooked'));
  const [isClassStarting, setIsClassStarting] = useState(false);
  const [sessionsDataOrig, setSessionsDataOrig] = useState<Session[]>();
  const [sessionsData, setSessionsData] = useState<Session[]>([]);
  // const [sessionsFilterData, setSessionsFilterData] = useState<Session[]>();
  const [upcomingSessions, setUpcomingSessions] = useState<Session[]>();
  const [bookedSessions, setBookedSessions] = useState<Session[]>();
  const [openBookedClassPopover, setOpenBookedClassPopover] = useState(false);
  const [openCancelledClassPopover, setOpenCancelledClassPopover] = useState(false);
  const [currentSession, setCurrenSession] = useState<Session>();
  const [enrollSessionErrorModal, setEnrollSessionErrorModal] = useState({
    visible: false,
    title: 'Token not valid',
  });
  const [showPreClass, setShowPreClass] = useState(false);
  const currentPageHeight = useRef(0);

  const tenantId = queryParams.get('tenandId') || 'test';

  const dispatch = useTypedDispatch();
  const { sessions, tutors, templates } = useTypedSelector((state) => state.sessions);
  const { token, user } = useTypedSelector((state) => state.user);
  const isMobile = useRef(true);
  const refSessionsWrap = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  enum ErrorTypes {
    INVALID_TOKEN = 'Token not valid',
    INVALID_SUBSCRIPTION = 'No subscription',
  }
  const ldClient = useLDClient();
  const { showAgreeScreen } = useFlags();
  const userAgreementStorage = useMemo(() => {
    try {
      return localStorage.getItem(`${user?.id}_consent_agreement`);
    } catch (e) {
      console.log('error:', e);
      return false;
    }
  }, []);
  const [userAgreement, setUserAgreement] = useState(!!userAgreementStorage);
  const [showUserAgreementScreen, setShowUserAgreementScreen] = useState(
    !userAgreement || !user?.name,
  );

  const [showPreScreen, setShowPreScreen] = useState(false);

  const enrollSession = async (sessionId: number, sessionTime: number) => {
    try {
      if (sessionId && user?.id && token) {
        const userEnroll = await dispatch(
          enrollSessionD(sessionId, tenantId, user.id, token) as any,
        );
        if (userEnroll.result && !!sessionsData) {
          setSessionsData(
            sessionsData.map((el) =>
              el.id === sessionId ? { ...el, enrollDate: new Date() } : el,
            ),
          );
          // Mixpanel metric:
          mixpanel.track('SessionBook', {
            SessionId: sessionId,
          });
          setOpenBookedClassPopover(true);
          setIsBooked(true);
          setTimeout(() => {
            setOpenBookedClassPopover(false);
          }, 3000);
          // Show pre class
          const currentSessionData = sessionsData.find((session) => {
            return session.id === sessionId;
          });
          setCurrenSession(currentSessionData);
          if (!testWeb) {
            window.mondlyNative
              ?.lessonBooked(`${user.id}${sessionId}`, sessionTime, {
                first: 2,
                second: 2,
              })
              .catch((err) => console.log(err));
          }
        } else {
          if (userEnroll.reason && userEnroll.reason === ErrorTypes.INVALID_SUBSCRIPTION) {
            if (!testWeb) {
              window.mondlyNative
                ?.showPremiumPopup({
                  sourceID: window.mondlyNative.buttonIds.TOPBAR_PREMIUM_BUTTON,
                })
                .catch((err) => console.log(err));
            }
            setEnrollSessionErrorModal({
              visible: true,
              title: 'You have no free session and no credit. You need to purchase a package',
            });
            console.log({
              msg: 'You have no free session and no credit. You need to purchase a package',
              errorType: ErrorTypes.INVALID_TOKEN,
            });
          } else if (userEnroll.reason && userEnroll.reason === ErrorTypes.INVALID_TOKEN) {
            setEnrollSessionErrorModal({
              visible: true,
              title: 'Mondly Session Expired, please sign in again',
            });
            console.log({
              msg: 'Mondly Session Expired, please sign in again',
              errorType: ErrorTypes.INVALID_TOKEN,
            });
          }
          console.log('failed to enroll session, ', sessionId);
        }
      }
    } catch (e: any) {
      console.log('error:', e.message);
      throw e;
    }
  };

  const cancelSession = async (sessionId: number) => {
    try {
      if (sessionId && user?.id && token) {
        // Report user press join session
        if (getContentEnv() === 'prod') {
          window.mondlyNative
            ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_ACTION, {
              lessonID: sessionId.toString(),
              actionID: window.mondlyNative.lessonActionIds.CANCEL,
              lessonStartTimestamp: Math.floor(Date.now() / 1000),
            })
            .catch((err) => console.log(err));
        }

        const userEnroll = await dispatch(
          cancelSessionD(sessionId, tenantId, user.id, token) as any,
        );
        if (userEnroll.result) {
          const updatedSessionData = sessionsData.map((innerSession) => {
            if (innerSession.id === sessionId) {
              // eslint-disable-next-line no-param-reassign
              delete innerSession.enrollDate;
            }
            return innerSession;
          });
          setSessionsData(updatedSessionData);
          // Refresh sessions when cancelling session:
          if (user?.id && token) {
            dispatch(getSessionsD(user.id, token, lang));
          }
          // Mixpanel metric:
          mixpanel.track('SessionCancel', {
            SessionId: sessionId,
          });
          // setSessionsFilterData(updatedSessionData);
          if (!testWeb) {
            window.mondlyNative
              ?.lessonCanceled(`${user.id}${sessionId}`)
              .catch((err) => console.log(err));
          }
        } else {
          console.log('missing params to cancel session: ', sessionId);
        }
        setOpenCancelledClassPopover(true);
        setTimeout(() => {
          setOpenCancelledClassPopover(false);
        }, 3000);
      }
    } catch (e: any) {
      console.log('error:', e.message);
      throw e;
    }
  };

  const joinSession = useCallback(
    (sessionId: number) => {
      try {
        const currentSessionData = sessionsData.find((session) => {
          return session.id === sessionId;
        });
        setCurrenSession(currentSessionData);
        // Send LD event for clicking the JOIN button
        ldClient?.track('join-session-click');
        // Mixpanel metric:
        mixpanel.track('JoinSession', {
          SessionId: sessionId,
        });
        if (sessionId && user?.id) {
          setShowPreClass(true);
          if (showAgreeScreen === 'Old' && userAgreement) {
            setShowPreScreen(true);
          } else {
            setShowUserAgreementScreen(false);
            setShowPreScreen(true);
          }
          currentPageHeight.current = window.pageYOffset;
          // Report user press join session
          if (getContentEnv() === 'prod') {
            window.mondlyNative
              ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_ACTION, {
                lessonID: sessionId.toString(),
                actionID: window.mondlyNative.lessonActionIds.JOIN,
                lessonStartTimestamp: Math.floor(Date.now() / 1000),
              })
              .catch((err) => console.log(err));
          }
        } else {
          console.log('missing params to join session');
        }
      } catch (e: any) {
        console.log('error:', e.message);
        throw e;
      }
    },
    [user?.id, sessionsData],
  );

  const buildUserData = (userName: string) => {
    if (user && token) {
      dispatch(updateUserName(userName));
      updateUserNameMondly(token, userName);
    }
    if (showAgreeScreen === 'Old') {
      setShowUserAgreementScreen(false);
    } else {
      setShowPreClass(false);
    }
  };

  useEffect(() => {
    isMobile.current = !(document.body.offsetWidth > 500);
  }, []);

  // Refresh sessions when changing tab:
  useMemo(() => {
    if (user?.id && token) {
      dispatch(getSessionsD(user.id, token, lang));
    }
  }, [isBooked]);

  useEffect(() => {
    // Send Mondly first tab open
    // TODO: report book tab
    if (getContentEnv() === 'prod') {
      window.mondlyNative
        ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.GENERAL_SCREEN_OPEN, {
          screenID: !isBooked
            ? window.mondlyNative.screenIds.UPCOMING_TAB
            : window.mondlyNative.screenIds.BOOKED_TAB,
        })
        .catch((err) => console.log(err));
    }
  }, [isMobile]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, currentPageHeight.current);
    }, 0);
    document
      .querySelector('meta[name=theme-color]')
      ?.setAttribute('content', showPreClass ? '#2270bb' : '#0f5599');
    document
      .querySelector('meta[name=apple-mobile-web-app-status-bar-style]')
      ?.setAttribute('content', showPreClass ? '#2270bb' : '#0f5599');
  }, [showPreClass]);

  // updates only on first render and refresh:
  useEffect(() => {
    setSessionsDataOrig(
      sessions?.sort(
        (a: Session, b: Session) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      ),
    );
  }, [sessions]);

  useEffect(() => {
    if (tutors && templates && Array.isArray(sessionsDataOrig)) {
      const updateData = sessionsDataOrig.map((el) => ({
        ...el,
        tutor: tutors[el.tutorId],
        template: templates[el.templateId],
      }));
      setSessionsData(updateData);
      // setSessionsFilterData(updateData);
    }
  }, [tutors, templates, sessionsDataOrig]);

  useEffect(() => {
    if (Array.isArray(sessionsDataOrig)) {
      if (difficulty === 1) {
        // Beginner user will see classes same as today
        setUpcomingSessions(
          sessionsDataOrig
            .filter((item) => !item.enrollDate)
            .filter((a: Session) => new Date(a.date).getTime() > new Date().getTime()),
        );
      } else {
        // Today we have juse beginner and intermediate classes
        setUpcomingSessions(
          sessionsDataOrig
            .filter((item) => !item.enrollDate)
            .filter((a: Session) => new Date(a.date).getTime() > new Date().getTime())
            .sort((a, b) => {
              if (a?.template?.level > b?.template?.level) {
                return -1;
              }
              return 0;
            }),
        );
      }
      setBookedSessions(sessionsDataOrig.filter((item) => item.enrollDate));
    }
  }, [difficulty, sessionsDataOrig]);

  useMemo(() => {
    if (sessionsDataOrig) {
      const bookedClasses = sessionsDataOrig?.filter((item) => item.enrollDate);
      const sessionStarting = bookedClasses?.filter((item: any) => {
        return differenceInMinutes(new Date(item.date), Date.now()) <= 15;
      });
      if (bookedClasses?.length > 0 && sessionStarting.length > 0) {
        setIsClassStarting(true);
      }
    }
  }, [sessionsDataOrig]);

  useMemo(() => {
    if (isClassStarting) {
      setIsBooked(true);
    }
  }, [isClassStarting]);

  const isLoader = (insessions?: Session[]) => {
    if (Array.isArray(insessions)) {
      return !isBooked ? (
        <p className="no-items-txt text-white">You do not have any classes</p>
      ) : (
        <p className="no-items-txt text-white">
          <FormattedMessage id="sessions.youDontHaveAnyClassesBooked" />
        </p>
      );
    }
    return <OvalLoader />;
  };

  return (
    <section
      className={`sessions__wrap ${
        (!isBooked && !upcomingSessions?.length) || (isBooked && !bookedSessions?.length)
          ? 'no-items'
          : ''
      } ${showPreClass ? 'pre-class-wrap' : ''}`}
    >
      {showPreClass && (
        <div className="pre-class">
          <div className="pre-class-header">
            <button
              type="submit"
              className="header-btn-close"
              onClick={() => {
                setShowPreClass(false);
                if (getContentEnv() === 'prod') {
                  window.mondlyNative
                    ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_SCREEN_QUIT, {
                      screenID: user?.name
                        ? window.mondlyNative.screenIds.WAITING
                        : window.mondlyNative.screenIds.PRE_SESSION,
                      lessonID: currentSession?.id.toString(),
                    })
                    .catch((err) => console.log(err));
                }
              }}
            >
              <img src={closeImage} alt="" />
            </button>
          </div>
          <PreClassScreen
            buildUserData={buildUserData}
            sessionData={currentSession!}
            user={user!}
            setTermsAndConditionsModal={setTermsAndConditionsModal}
            showUserAgreementScreen={showUserAgreementScreen}
            showPreScreen={showPreScreen}
            userAgreement={userAgreement}
            setUserAgreement={setUserAgreement}
            setShowPreScreen={setShowPreScreen}
            showAgreeScreen={showAgreeScreen}
            enrollSession={enrollSession}
          />
        </div>
      )}

      {/* <div className="menu-icons">
        {isMobile.current ? (
          <>
            <IconAutocomplete//
              ButtonName="Level"
              icon={<GradingIcon/>}
              options={Levels}
              onChange={(val:any) => {
                if (val) {
                  setLevelFilter(val);
                } else {
                  setLevelFilter([]);
                }
              }}
              optionLabel={(e) => e.description + (e.level ? ` (${e.level})` : '')}
            />
            <IconAutocomplete
              ButtonName="Day"
              icon={<TodayIcon/>}
              options={DaysFull}
              onChange={(val) => {
                if (val) {
                  setDaysFilter(val);
                } else {
                  setDaysFilter([]);
                }
              }}
              optionLabel={(e) => e}
            />
            <IconAutocomplete
              ButtonName="Time"
              icon={<AccessTimeIcon/>}
              options={Times}
              onChange={(val) => {
                if (val) {
                  setTimesFilter(val);
                } else {
                  setTimesFilter([]);
                }
              }}
              optionLabel={(e) => e.description}
            />
          </>
        ) : (
          <>
            <Autocomplete
              disablePortal
              id="combo-levels"
              options={Levels}
              getOptionLabel={(e) => e.description + (e.level ? ` (${e.level})` : '')}
              sx={{
                width: 260,
              }}
              onChange={(e, val) => {
                if (val) {
                  // setLevelFilter(val.level.toLowerCase());
                } else {
                  // setLevelFilter();
                }
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="Level"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      backgroundColor: 'white',
                      borderRadius: 10,
                    },
                  }}
                />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-levels"
              options={DaysFull}
              getOptionLabel={(e) => e}
              sx={{
                width: 170,
              }}
              onChange={(e, val) => {
                if (val) {
                  // setDaysFilter(val);
                } else {
                  // setDaysFilter();
                }
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="Days"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      backgroundColor: 'white',
                      borderRadius: 10,
                    },
                  }}
                />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-levels"
              options={Times}
              getOptionLabel={(e) => e.description}
              sx={{
                width: 230,
              }}
              onChange={(e, val) => {
                if (val) {
                  // setTimesFilter(val);
                } else {
                  // setTimesFilter();
                }
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="Times"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      backgroundColor: 'white',
                      borderRadius: 10,
                    },
                  }}
                />
              )}
            />
          </>
        )}
      </div> */}

      {!showPreClass && (
        <TabSwitcher
          isBooked={isBooked}
          bodyScrollHeight={bodyScrollHeight}
          setIsBooked={setIsBooked}
          isClassStarting={isClassStarting}
        />
      )}
      {(!showPreClass && (!isBooked ? upcomingSessions : bookedSessions)?.length && (
        <div className="session__itemsWrap" ref={refSessionsWrap}>
          {(!isBooked ? upcomingSessions : bookedSessions)?.map((_session) => (
            <ClassCard
              testWeb={testWeb}
              isBooked={isBooked}
              session={_session}
              key={_session.id}
              enrollSession={enrollSession}
              cancelSession={cancelSession}
              joinSession={joinSession}
              bookedSessionsLength={sessionsData.filter((item) => item.enrollDate).length || 0}
              updatedSessions={sessionsData}
              showAgreeScreen={showAgreeScreen}
              setShowPreClass={setShowPreClass}
              setShowPreScreen={setShowPreScreen}
              setShowUserAgreementScreen={setShowUserAgreementScreen}
              setCurrenSession={setCurrenSession}
            />
          ))}
        </div>
      )) ||
        (!showPreClass && (
          <div className="session__noItemsWrap">
            {isLoader(!isBooked ? upcomingSessions : bookedSessions)}
          </div>
        ))}

      {openBookedClassPopover && (
        <BottomConfirmationPopup
          confirmationType="booked"
          msgTxt={intl.formatMessage({
            id: 'bottomConfirmationPopup.youAreNowbookedInToThisClass',
          })}
        />
      )}
      {openCancelledClassPopover && (
        <BottomConfirmationPopup
          confirmationType="cancelled"
          msgTxt={intl.formatMessage({
            id: 'bottomConfirmationPopup.yourClassBookingHaseBeenCancelled',
          })}
        />
      )}

      <Modal
        open={enrollSessionErrorModal.visible}
        onClose={() => setEnrollSessionErrorModal({ ...enrollSessionErrorModal, visible: false })}
      >
        <div className="modal-panel modal-panel__error">
          <div className="modal-content">
            <h2 className="modal-content__title modal-content__title-error">Error</h2>
            <h3>{enrollSessionErrorModal.title}</h3>
          </div>
          <div className="modal-footer">
            <button
              className="modal-btn modal-btn__default"
              type="button"
              onClick={() =>
                setEnrollSessionErrorModal({ ...enrollSessionErrorModal, visible: false })
              }
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default Sessions;
