import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import mixpanel from 'mixpanel-browser';
import { Modal } from '@mui/material';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Sessions from './Components/Sessions/Sessions';
import WelcomeModal from './Components/Modals/WelcomeModal';
import LiveSession from './Components/LiveSession/LiveSession';
import TermsAndConditions from './Components/TermsAndConditions/TermsAndConditions';
import Header from './Components/Header/Header';
import PostSessionScreens from './Components/Feedback/PostSessionScreens';
import BottomConfirmationPopup from './Components/Shared/BottomConfirmationPopup';
import './assets/styles/main.scss';

import { useTypedDispatch, useTypedSelector } from './hooks/useTypeSelector';
import { fetchUser } from './redux/actionCreators/UserReducerAC';
import OvalLoader from './assets/loader/oval';
import { getContentEnv } from './helpers/Network';

interface AppProps {
  lang: string;
  difficulty: number;
}

function App({ lang, difficulty }: AppProps) {
  const [welcomeScreenModal, setWelcomeScreenModal] = useState(false);
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
  const [isFeedbackScreen, setIsFeedbackScreen] = useState(false);
  const [bodyScrollHeight, setBodyScrollHeight] = useState(0);
  const dispatch = useTypedDispatch();
  const { user } = useTypedSelector((state) => state.user);
  const { sessions } = useTypedSelector((state) => state.sessions);
  const [isShowBottomPopup, setIsShowBottomPopup] = useState(false);
  const [bottomPopupType, setBottomPopupType] = useState('');
  const [bottomPopupTxt, setBottomPopupTxt] = useState('');

  const onShowBottomPopup = (type: string, txt: string) => {
    setBottomPopupType(type);
    setBottomPopupTxt(txt);
    setIsShowBottomPopup(true);
    setTimeout(() => {
      setIsShowBottomPopup(false);
    }, 3000);
  };

  const ldClient = useLDClient();
  // MIXPANEL:
  mixpanel.init(process.env.REACT_APP_PUBLIC_MIXPANEL_CLIENT_SIDE_TOKEN!, {
    debug: false,
  });
  useEffect(() => {
    if (user?.id) {
      mixpanel.identify(user?.id);
    }
  }, [user?.id]);

  useEffect(() => {
    ldClient?.waitUntilReady().then(() => {
      if (user?.id) {
        ldClient.identify({ key: user?.id });
      }
    });
  }, [ldClient, user?.id]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (!params.testWeb && getContentEnv() === 'prod') {
      window.mondlyNative
        ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.OPEN, {})
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    dispatch(fetchUser(!!params.testWeb, params.userId));
  }, [dispatch]);

  useEffect(() => {
    try {
      // Mixpanel metric:
      mixpanel.track('TutoringLaunch', { language: lang, version: 'v1' });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    document
      .querySelector('meta[name=theme-color]')
      ?.setAttribute('content', welcomeScreenModal ? '#0a2a4a' : '#0f5599');
    document
      .querySelector('meta[name=apple-mobile-web-app-status-bar-style]')
      ?.setAttribute('content', welcomeScreenModal ? '#0a2a4a' : '#0f5599');
  }, [welcomeScreenModal]);

  function handleWelcomeScreenModal() {
    try {
      if (user?.id && !localStorage.getItem(`${user.id}-isVisit`)) {
        localStorage.setItem(`${user.id}-isVisit`, JSON.stringify(true));
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
    setWelcomeScreenModal(false);
  }

  function handleInfoBtn() {
    setWelcomeScreenModal(true);
  }

  const bodyRef = useCallback((node: HTMLDivElement | null) => {
    function onScroll() {
      if (node !== null) {
        setBodyScrollHeight(node.scrollTop);
      }
    }
    if (node !== null) {
      node.addEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => {
    try {
      if (sessions?.length && user?.id && !localStorage.getItem(`${user.id}-isVisit`)) {
        setTimeout(() => {
          setWelcomeScreenModal(true);
        }, 2000);
      }

      // eslint-disable-next-line no-empty
    } catch (err) {}
  }, [user?.id, sessions?.length]);

  return (
    <BrowserRouter>
      <Header
        bodyScrollHeight={bodyScrollHeight}
        isTermsAndConditions={false}
        setIsFeedbackScreen={setIsFeedbackScreen}
        isFeedbackScreen={isFeedbackScreen}
      />
      <div className="bodyWrapper" ref={bodyRef}>
        {user?.id ? (
          <Routes>
            <Route
              path="/"
              element={
                <Sessions
                  bodyScrollHeight={bodyScrollHeight}
                  setTermsAndConditionsModal={setTermsAndConditionsModal}
                  lang={lang}
                  difficulty={difficulty}
                />
              }
            />
            <Route
              path="/session/:sessionId"
              element={
                <LiveSession
                  onShowBottomPopup={(type: string, txt: string) => onShowBottomPopup(type, txt)}
                />
              }
            />
            <Route path="/sessionend/:sessionId" element={<PostSessionScreens />} />
          </Routes>
        ) : (
          <div className="loaderWrapper">
            <OvalLoader />
          </div>
        )}
      </div>
      {!welcomeScreenModal && !isFeedbackScreen && (
        <button className="welcome-modal-i-btn" type="submit" onClick={handleInfoBtn}>
          <span />
        </button>
      )}

      {isShowBottomPopup && (
        <BottomConfirmationPopup confirmationType={bottomPopupType} msgTxt={bottomPopupTxt} />
      )}

      <Modal open={welcomeScreenModal} onClose={() => handleWelcomeScreenModal()}>
        <div className="modal__welcome">
          <WelcomeModal />
          <button type="submit" className="cta-btn" onClick={handleWelcomeScreenModal}>
            <FormattedMessage id="welcomeModal.startLearning" />
          </button>
        </div>
      </Modal>
      {termsAndConditionsModal && (
        <div className="terms-and-conditions-div">
          <Header
            bodyScrollHeight={bodyScrollHeight}
            isTermsAndConditions
            setTermsAndConditionsModal={setTermsAndConditionsModal}
          />
          <TermsAndConditions />
        </div>
      )}
    </BrowserRouter>
  );
}

export default App;
