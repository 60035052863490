import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import mixpanel from 'mixpanel-browser';
import crownImage from '../../assets/image/crown.png';
import closeImage from '../../assets/image/close.png';
import goBackBtnImage from '../../assets/image/go-back-btn.png';
import { useTypedSelector } from '../../hooks/useTypeSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { getContentEnv } from '../../helpers/Network';

interface HeaderProps {
  bodyScrollHeight: number;
  isTermsAndConditions: boolean;
  setTermsAndConditionsModal?: (a: boolean) => void;
  setIsFeedbackScreen?: (a: boolean) => void;
  isFeedbackScreen?: boolean;
}

function Header({
  bodyScrollHeight,
  isTermsAndConditions = false,
  setTermsAndConditionsModal = undefined,
  setIsFeedbackScreen = undefined,
  isFeedbackScreen = false,
}: HeaderProps) {
  const { user } = useTypedSelector((state) => state.user);
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const screenWidth = windowSize.width || 768;
  const [isScrolled, setIsScrolled] = useState(false);
  const isSessionEnd = location.includes('/sessionend/');
  const sessionId = isSessionEnd ? location.match('/sessionend/(.*)')?.[1] : '-1';

  useEffect(() => {
    if (
      (screenWidth < 768 && bodyScrollHeight > 71) ||
      (screenWidth >= 768 && bodyScrollHeight > 116)
    ) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [bodyScrollHeight, screenWidth]);

  useEffect(() => {
    if (isSessionEnd && setIsFeedbackScreen) {
      setIsFeedbackScreen(true);
    }
  }, []);

  function handleCloseBtn() {
    if (isSessionEnd) {
      try {
        // Mixpanel metric:
        mixpanel.track('FeedbackClose');
      } catch (err) {
        console.log(err);
      }
      if (getContentEnv() === 'prod') {
        window.mondlyNative
          ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_SCREEN_QUIT, {
            screenID: window.mondlyNative.screenIds.FEEDBACK,
            lessonID: sessionId?.toString(),
          })
          .catch((err) => console.log(err));
      }

      navigate('/');
    } else {
      try {
        // Mixpanel metric:
        mixpanel.track('TutoringExit');
      } catch (err) {
        console.log(err);
      }
      console.log({ msg: 'Close window' });
      window.mondlyNative?.closeWebView().catch((err) => console.log(err));
    }
  }

  function handleSubscriptionBtn() {
    console.log({ msg: 'Subscription window' });
    window.mondlyNative
      ?.showPremiumPopup({ sourceID: window.mondlyNative.buttonIds.TOPBAR_PREMIUM_BUTTON })
      .catch((err) => console.log(err));
  }

  const getHeaderTitle = () => {
    if (isTermsAndConditions) {
      return <FormattedMessage id="preClassScreen.termsAndConditions" />;
    }
    if (isSessionEnd) {
      return <FormattedMessage id="feedback.feedback" />;
    }
    return 'Mondly Class';
  };

  return (
    <div
      className={`header ${isScrolled ? 'isScrolled' : ''}  ${
        isSessionEnd ? 'feedback-header' : ''
      } `}
    >
      <div className="header-row">
        {!isTermsAndConditions && (
          <button type="submit" className="header-btn-close" onClick={handleCloseBtn}>
            <img src={closeImage} alt="" />
          </button>
        )}
        {isTermsAndConditions && (
          <button
            type="submit"
            className="go-back-btn"
            onClick={() => setTermsAndConditionsModal?.(false)}
          >
            <img src={goBackBtnImage} alt="" />
          </button>
        )}

        <div className="header__text">
          <p>{getHeaderTitle()}</p>
        </div>
        <button
          type="submit"
          className={`header-btn-action ${
            user?.roles.premium === undefined || user.roles.premium || isFeedbackScreen
              ? 'hide'
              : ''
          }`}
          onClick={handleSubscriptionBtn}
        >
          <img src={crownImage} alt="" />
        </button>
      </div>
    </div>
  );
}

export default Header;
