import { useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { joinNewSession } from '../../helpers/Network';
import { useTypedSelector } from '../../hooks/useTypeSelector';

// eslint-disable-next-line react/prop-types
function LiveSession({ onShowBottomPopup }) {
  const { sessionId } = useParams();
  const [queryParams] = useSearchParams();

  const testWeb = queryParams.get('testWeb');
  const { user, token } = useTypedSelector((state) => state.user);
  const navigate = useNavigate();
  const ldClient = useLDClient();

  const joinSessionUrl = () => {
    joinNewSession(sessionId, token, user.name)
      .then((session) => {
        if (session?.session) {
          // Send event to LD when user join class
          if (testWeb) {
            window.location.replace(session.session);
          } else {
            // Report user press join session
            window.mondlyNative
              ?.sendAnalyticsEvent(window.mondlyNative.analyticsTypes.LESSON_START, {
                lessonID: sessionId.toString(),
                lessonStartTimestamp: Math.floor(Date.now() / 1000),
              })
              .catch((err) => console.log(err));
            window.mondlyNative?.startLiveSession(session.session).catch((err) => console.log(err));
            try {
              // Mixpanel metric:
              mixpanel.track('SessionStart', {
                SessionId: session.id,
              });
            } catch (err) {
              console.log(err);
            }

            // Go back to booked tab
            navigate('/?showBooked=1');
          }
        } else {
          onShowBottomPopup('error', 'There was an error loading your session, Please try again.');
          setTimeout(() => {
            navigate('/?showBooked=1');
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    ldClient.track('join-session');
    setTimeout(() => {
      joinSessionUrl();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="live-session loading-wrapper">
      <p>Preparing your session</p>
      <div className="dot-pulse" />
    </section>
  );
}
export default LiveSession;
