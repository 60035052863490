import { combineReducers } from 'redux';
import SessionsReducer from './SessionsReducer';
import UserReducer from './UserReducer';
import DeviceReducer from './DeviceReduce';

const reducers = combineReducers({
  sessions: SessionsReducer,
  user: UserReducer,
  device: DeviceReducer,
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;
